import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EmailDetail from "./EmailDetail";
import SuccessPopup from "../Setting/Sucesspopup";
import ConfirmationModal from "../Setting/ConfirmationModal";

import {
  getLiveEmailSentToMember,
  getMemberScheduleEmail,
  deleteEmail,
  getEmailTemplate,
  deleteEmailTemplate,
  getEmailAutomation,
  delateEmailAutomation,
  startOrstopAutomation,
  getAnEmailTemplate,
} from "../../helper/service.ts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faEllipsisVertical,
  faEnvelopeOpenText,
  faCopy,
  faTrashCan,
  faPen,
  faEnvelope,
  faCirclePlay,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import searchicon from "../../images/admin/search-dark.svg";

const EmailList = (props) => {
  const navigate = useNavigate();
  const [emailsToShow, setEmailsToShow] = useState(10);
  const [buttonShow, setButtonShow] = useState(emailsToShow);
  const [activeTab, setActiveTab] = useState("sentEmails");
  const [liveEmail, setLiveEmails] = useState("");
  const [scheduleEmail, setScheduleEmails] = useState("");
  const [showEmailDetail, setShowEmailDetailModal] = useState(false);
  const [emailDetail, setEmailDetail] = useState({});
  const [sentEmailCount, setSentEmailCount] = useState(0);
  const [schEmailCount, setSchEmailCount] = useState(0);
  const [isFilter, setIsFilter] = useState(true);
  const [originalSentEmails, setOrgSentEmails] = useState([]);
  const [originalSchEmails, setOrgSchEmails] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [mdlMessage, setMdlMessage] = useState("");
  const [emailTemplate, setEmailTemplates] = useState(null);
  const [orgEmailTemplate, setOrgEmailTemplates] = useState(null);
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [deleteType,setDeleteType] = useState('');
  const [deleteId,setDeleteId]= useState("");
  const [automationData,setAutomationData] = useState(null);
  const [orgAutomationData,setOrgAutomationData] = useState(null);
  useEffect(() => {
    getSentEmails();
    getScheduledEmails();
    getTemplatesEmails();
    getAllAutomations();
  }, []);

  const getAllAutomations = async () => {
    await getEmailAutomation().then((data) => {
      setOrgAutomationData(data.result)
      iterateAutomation(data.result);
    });
  };
  const getTemplatesEmails = async () => {
    await getEmailTemplate().then((data) => {
      setOrgEmailTemplates(data.result)
      iterateEmails(data.result, "template");
    });
  };

  const getSentEmails = async () => {
    await getLiveEmailSentToMember().then((data) => {
      iterateEmails(data.result, "live"); //displaying in table
      setSentEmailCount(data.result.length);
      setOrgSentEmails(data.result);
      props.prepareGauge(data?.emailsAllowed || 0, data?.emailsThisMonth || 0);
    });
  };
  const getScheduledEmails = async () => {
    await getMemberScheduleEmail().then((data) => {
      iterateEmails(data.result, "schedule");
      setOrgSchEmails(data.result);
      setSchEmailCount(data.result.length);
    });
  };
  const openEmailDetailModal = (email) => {
    setEmailDetail(email);
    setShowEmailDetailModal(true);
  };
  const handleEmaileDuplication = (emailSubj, emailMsg) => {
    const emailSubject = `Duplicate: ${emailSubj}`;
    navigate("/create-email", {
      state: { emailSubject, emailMsg },
    });
  };
  const handleTemplateDuplication = (email) => {
    const templateSubject = `Duplicate: ${email.templateSubject}`;
    email.templateSubject = templateSubject;

    navigate("/create-email-template", {
      state: { email },
    });
  };
  const handleEmailTemplateCreation = (email) => {
    navigate("/create-email-template", {
      state: { email },
    });
  };
  const editEmaileTemplate = (email) => {
    navigate(`/create-email-template/${email._id}`, {
      state: { email },
    });
  };
  const handleDeleteEmailTemplate = async (id) => {
    setWarningModal(true);
    setMdlMessage("Are you sure you want to Delete this template");
    setModalTitle("Action Require!!");
    setModalType("delete");
    if (isDelete) {
      await deleteEmailTemplate(id).then((res) => {
        if (res.status === 200) {
          setIsSuccess(true);
          setMdlMessage("Email Template Deleted Successfully!!");
          getTemplatesEmails();
          setIsDelete(false);
        }
      });
    } else {
      setIsDelete(false);
    }
  };

  const startstopAutomation = async (data) =>{
    var msg = '' ;
    if(data.automationStatus === "on")
    {
      msg = "Email Automation Stopped Successfully!!";
      data.automationStatus = "off";
    }else{
      msg = "Email Automation Started Successfully!!"
      data.automationStatus = "on";
    }
    await startOrstopAutomation(data).then((res) => {
      if (res.status === 200) {
        getAllAutomations();
        setIsSuccess(true);
        setMdlMessage(`${msg}`);
      }
    });
  }

  const editAutomation = (id) =>{
    navigate(`/email-automation/${id}`);
  }

  const converDate = (date) => {
    const originalDate = new Date(date);
    const selectedTimeZone = localStorage.getItem("websiteTimeZone");
    const convertedDate = originalDate.toLocaleString("en-US", {
      timeZone: selectedTimeZone,
      hour12: true,
    });
    var sentDate = new Date(convertedDate);
    var textData = "";
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let strtDate = convertedDate.split("/");
    var months = parseInt(strtDate[0] - 1);
    textData =
      strtDate[1] + "-" + monthNames[months] + "-" + sentDate.getUTCFullYear();
    return textData;
  };
  const sortEmails = (data, type) => {
    const sortedData = data.sort((a, b) => {
      if (type === "live") {
        const dateA = a.emailSchedule === "" ? a.createdAt : a.emailSchedule;
        const dateB = b.emailSchedule === "" ? b.createdAt : b.emailSchedule;
        return new Date(dateB) - new Date(dateA);
      } else if (type === "schedule") {
        return new Date(b.emailSchedule) - new Date(a.emailSchedule);
      }
    });
    return sortedData;
  };
  const filterByDate = (value) => {
    if (value) {
      setIsFilter((prev) => !prev);
      const sortedSentData = originalSentEmails.sort((a, b) => {
        const dateA = a.emailSchedule === "" ? a.createdAt : a.emailSchedule;
        const dateB = b.emailSchedule === "" ? b.createdAt : b.emailSchedule;
        return new Date(dateB) - new Date(dateA);
      });
      iterateEmails(sortedSentData, "live");

      const sortedSchData = originalSchEmails.sort((a, b) => {
        return new Date(b.emailSchedule) - new Date(a.emailSchedule);
      });
      iterateEmails(sortedSchData, "schedule");
    } else {
      setIsFilter((prev) => !prev);
      const data1 = sortEmails(originalSentEmails, "live");
      iterateEmails(data1, "live");

      const data2 = sortEmails(originalSchEmails, "schedule");
      iterateEmails(data2, "schedule");
    }
  };
  
  const searchEmail = (event) => {
    event.preventDefault();
    const originalTemplate = [...orgEmailTemplate];

    const { value } = event.target;
    
    if (value !== "") {
      const filteredResult = originalSentEmails.filter((ele) =>
        ele.emailSubject.includes(value.toLowerCase())
      );
      iterateEmails(filteredResult, "live");

      const filteredSchResult = originalSchEmails.filter((ele) =>
        ele.emailSubject.toLowerCase().includes(value.toLowerCase())
      );
      iterateEmails(filteredSchResult, "schedule");

      const filteredTemplate = originalTemplate.filter((ele) =>
        ele.templateSubject.includes(value.toLowerCase())
      );
      iterateEmails(filteredTemplate, "template");
    } else {
      iterateEmails(originalSentEmails, "live");
      iterateEmails(originalSchEmails, "schedule");
      iterateEmails(originalTemplate, "template");
    }
  };
  const handleDelete = (id,type) => {
    setWarningModal(true);
    setMdlMessage(`Are you sure you want to delete this ${type}`);
    setModalTitle("Action Require!!");
    setModalType("delete");
    setDeleteType(type);
    setDeleteId(id);
  }
  const handleDeleteEmail = async (id) => {
    if (isDelete) {
      await deleteEmail(id).then((res) => {
        if (res.status === 200) {
          setIsSuccess(true);
          setMdlMessage("Email Deleted Successfully!!");
          getScheduledEmails();
        }
      });
    } else {
      setIsDelete(false);
    }
  };
  const findTemplate = async (id) => {
    const res = await getAnEmailTemplate(id);
    if (res.message === "Email template found") {
      return res?.result?.templateSubject;
    }
    return "Default Template";
  };
  
  const iterateAutomation = async (data) => {
    if (data) {
      
      const templates = await Promise.all(
        data.map(async (email) => {
          if (email.emailTempelate) {
            return await findTemplate(email.emailTempelate);
          }
          return "n/a";
        })
      );
  
      const displayData = data.map((email, index) => {
        const selectedTemplate = templates[index];
        return (
          <tr key={email._id}>
            <>
              <td>{email.automationName}</td>
              <td>{email?.triggerType}</td>
              <td>{email?.delayTime ? `${email?.delayTime} ${email?.delayType}` : 'N/A'}</td>
              <td>{selectedTemplate}</td>
              <td>{email?.automationStatus.toUpperCase()}</td>
              <td>
                <div className="dropdown dropright">
                  <span
                    id="email-option"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-center pointer"
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      color="#12aeb4"
                      width={25}
                      height={25}
                      style={{ height: "25px" }}
                    />
                  </span>
                  <div
                    className="dropdown-menu px-1 py-0"
                    aria-labelledby="email-option"
                    style={{ zIndex: 5, left: "25px" }}
                  >
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center w-100"
                      onClick={() => editAutomation(email._id)}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Edit
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center"
                      onClick={() => handleDelete(email._id, "automation")}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Delete
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center"
                      onClick={() => startstopAutomation(email)}
                    >
                      <FontAwesomeIcon
                        icon={
                          email?.automationStatus === "off"
                            ? faCirclePlay
                            : faCirclePause
                        }
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      {email?.automationStatus === "off" ? "Start" : "Stop"}
                    </button>
                  </div>
                </div>
              </td>
            </>
          </tr>
        );
      });
  
      setAutomationData(displayData);
    }
  };  
  

  const iterateEmails = (data, type) => {
    if (data) {
      const displayData = data.map((email) => {
        var originalDate = "",
          updatedDate = "";
        if (type === "live") {
          if (email.emailSchedule === "") {
            originalDate = converDate(email.createdAt);
          } else {
            originalDate = converDate(email.emailSchedule);
          }
        } else if (type === "schedule") {
          originalDate = converDate(email.emailSchedule);
        } else if (type === "template") {
          originalDate = converDate(email.createdAt);
          updatedDate = converDate(email.updatedAt);
        }
        return (
          <tr key={email._id}>
            {type !== "template" ? (
              <>
                <td>{originalDate}</td>
                <td>{email?.emailSubject}</td>
                <td>
                  {email?.recipientsMember?.length > 0
                    ? email?.recipientsMember?.length
                    : 0}
                </td>
              </>
            ) : (
              <>
                <td>{email.templateSubject}</td>
                <td>{originalDate}</td>
                <td>{updatedDate}</td>
              </>
            )}
            <td className="">
              {type !== "template" ? (
                <div className="dropdown dropright">
                  <span
                    id="email-option"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-center pointer"
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      color="#12aeb4"
                      width={25}
                      height={25}
                      style={{ height: "25px" }}
                    />
                  </span>
                  <div
                    className="dropdown-menu px-1 py-0"
                    aria-labelledby="email-option"
                    style={{ zIndex: 5, left: "25px" }}
                  >
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center w-100 px-1 py-0"
                      style={{fontSize:"14px"}}
                      onClick={() => openEmailDetailModal(email)}
                    >
                      <FontAwesomeIcon
                        icon={faEnvelopeOpenText}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Open
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center w-100 px-1 py-0"
                      style={{fontSize:"14px"}}
                      onClick={() =>
                        handleEmaileDuplication(
                          email.emailSubject,
                          email.emailMessage
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Duplicate
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center w-100 px-1 py-0"
                      style={{fontSize:"14px"}}
                      onClick={() =>
                        handleEmailTemplateCreation(email)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Create Template
                    </button>
                    {type === "schedule" && (
                      <button
                        className="dropdown-item d-flex gap-2 align-items-center px-1 py-0"
                        style={{fontSize:"14px"}}
                        onClick={() => handleDelete(email._id,"email")}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color="#12aeb4"
                          width={20}
                          height={20}
                        />
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="dropdown dropright">
                  <span
                    id="email-option"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="text-center pointer"
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisVertical}
                      color="#12aeb4"
                      width={25}
                      height={25}
                      style={{ height: "25px" }}
                    />
                  </span>
                  <div
                    className="dropdown-menu px-1 py-0"
                    aria-labelledby="email-option"
                    style={{ zIndex: 5, left: "25px" }}
                  >
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center w-100"
                      onClick={() => editEmaileTemplate(email)}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Edit
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center"
                      onClick={() => handleDelete(email._id,"template")}
                    >
                      <FontAwesomeIcon
                        icon={faTrashCan}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Delete
                    </button>
                    <button
                      className="dropdown-item d-flex gap-2 align-items-center"
                      onClick={() => handleTemplateDuplication(email)}
                    >
                      <FontAwesomeIcon
                        icon={faCopy}
                        color="#12aeb4"
                        width={20}
                        height={20}
                      />
                      Duplicate
                    </button>
                  </div>
                </div>
              )}
            </td>
          </tr>
        );
      });
      if (type === "live") {
        setLiveEmails(displayData);
      } else if (type === "schedule") {
        setScheduleEmails(displayData);
      } else if (type === "template") {
        setEmailTemplates(displayData);
      }
    } else {
      if (type === "live") {
        setLiveEmails("No Data Found");
      } else if (type === "schedule") {
        setScheduleEmails("No Scheduled Email Found");
      }
    }
  };
  const loadMore = () => {
    if (emailsToShow >= sentEmailCount) {
      setEmailsToShow(10);
    } else {
      setEmailsToShow((prevMembersToShow) => prevMembersToShow + 10);
    }
  };
  const handleDeleteAction = async (id) =>{
    if(deleteType==="automation"){
      await delateEmailAutomation(id).then((res) => {
        if (res.status === 200) {
          setIsSuccess(true);
          setMdlMessage("Email Automation Deleted Successfully!!");
          getAllAutomations();
          setDeleteType('');
          setDeleteId("");
        }
      });
    }else if(deleteType==="template"){
      await deleteEmailTemplate(id).then((res) => {
        if (res.status === 200) {
          setIsSuccess(true);
          setMdlMessage("Email Template Deleted Successfully!!");
          getTemplatesEmails();
          setDeleteType('');
          setDeleteId("");
        }
      });
    }
    else if(deleteType==="email"){
      await deleteEmail(id).then((res) => {
        if (res.status === 200) {
          setIsSuccess(true);
          setMdlMessage("Email Deleted Successfully!!");
          getScheduledEmails();
          setDeleteId("");
          setDeleteType('');
        }
      });
    }
  }
  return (
    <>
      {showWarningModal && (
        <ConfirmationModal
          title={modalTitle}
          type={modalType}
          message={mdlMessage}
          onConfirm={() => {
            handleDeleteAction(deleteId);
            setWarningModal(false);
            setMdlMessage("");
            setModalTitle("");
            setModalType("");
          }}
          onCancel={() => {
            setWarningModal(false);
            setMdlMessage("");
            setModalTitle("");
            setModalType("");
            setDeleteType('');
            setDeleteId("");
          }}
        />
      )}
      {isSuccess && (
        <SuccessPopup
          message={mdlMessage}
          closeSuccessModel={() => {
            setIsSuccess(false);
            setMdlMessage("");
          }}
        />
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-end pr-2">
            {/* <button
            type="button"
            className="email-btn-custom mr-4"
            style={{ width: "10%" }}
            onClick={()=>filterByDate(isFilter)}
          >
            <FontAwesomeIcon icon={faFilter} /> Filter
          </button> */}
            <div className="search-email" style={{ height: "42px" }}>
              <input
                type="text"
                name="searchEmail"
                className="form-control"
                placeholder="Search"
                onChange={searchEmail}
              />
              <span className="search-icon">
                <img src={searchicon} />
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <ul className="nav nav-tabs" id="coursesTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeTab === "sentEmails" ? "active" : ""
                  }`}
                  id="sentEmails-tab"
                  data-toggle="tab"
                  href="#sentEmails"
                  role="tab"
                  aria-controls="sentEmails"
                  aria-selected={activeTab === "sentEmails"}
                >
                  Sent Emails
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeTab === "scheduledEmails" ? "active" : ""
                  }`}
                  id="scheduledEmails-tab"
                  data-toggle="tab"
                  href="#scheduledEmails"
                  role="tab"
                  aria-controls="scheduledEmails"
                  aria-selected={activeTab === "scheduledEmails"}
                >
                  Scheduled Emails
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeTab === "templateEmails" ? "active" : ""
                  }`}
                  id="templateEmails-tab"
                  data-toggle="tab"
                  href="#templateEmails"
                  role="tab"
                  aria-controls="templateEmails"
                  aria-selected={activeTab === "templateEmails"}
                >
                  Emails Templates
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link ${
                    activeTab === "automationEmails" ? "active" : ""
                  }`}
                  id="automationEmails-tab"
                  data-toggle="tab"
                  href="#automationEmails"
                  role="tab"
                  aria-controls="automationEmails"
                  aria-selected={activeTab === "automationEmails"}
                >
                  Email Automations
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="sentEmails"
                role="tabpanel"
                aria-labelledby="sentEmails-tab"
              >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Date Sent</td>
                        <td>Subject</td>
                        <td>Qty Recipients</td>
                        {/* <td>Open Rate</td> */}
                        <td>Actions</td>
                      </tr>
                    </thead>
                    <tbody>{liveEmail.slice(0, emailsToShow)}</tbody>
                  </table>
                </div>
                <div className="flex d-flex text-align-center justify-content-center">
                  {buttonShow < sentEmailCount &&
                    ((sentEmailCount > emailsToShow && (
                      <button
                        className="load-more-button mb-3"
                        onClick={loadMore}
                      >
                        Load More
                      </button>
                    )) ||
                      (sentEmailCount <= emailsToShow && (
                        <button
                          className="load-more-button mb-3"
                          onClick={loadMore}
                        >
                          Show Less
                        </button>
                      )))}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scheduledEmails"
                role="tabpanel"
                aria-labelledby="scheduledEmails-tab"
              >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Date Scheduled</td>
                        <td>Subject</td>
                        <td>Qty Recipients</td>
                        {/* <td>Open Rate</td> */}
                        <td>Actions</td>
                      </tr>
                    </thead>
                    <tbody>{scheduleEmail}</tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="templateEmails"
                role="tabpanel"
                aria-labelledby="templateEmails-tab"
              >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td style={{ width: "40%" }}>Subject</td>
                        <td>Date Created</td>
                        <td>Date Updated</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>{emailTemplate}</tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="automationEmails"
                role="tabpanel"
                aria-labelledby="automationEmails-tab"
              >
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Automation Name</td>
                        <td>Condition</td>
                        <td>Schedule</td>
                        <td>Selected Template</td>
                        <td>Status</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>{automationData}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEmailDetail && (
        <EmailDetail
          emailDetail={emailDetail}
          onClose={() => setShowEmailDetailModal(false)}
        />
      )}
    </>
  );
};

export default EmailList;
