import React from 'react'

export interface DropdonwOption {
    name: string;
    value: string;
}

export interface DropdownWithOptionsProps {
    text: string;
    options: DropdonwOption[];
    optionHandler: (option: string) => void;
}

export default function DropdownWithOptions({ text, options, optionHandler }: DropdownWithOptionsProps) {
    return (
        <div className='dropdown admin-courses for-drop-down '>
            <button
                type="button"
                id="dropdownMenuOption"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="custom-button-design submit-btn dropdown-toggle"
            >
                {text}
            </button>
            <div className="dropdown-menu mt-0" aria-labelledby="dropdownMenuOption" style={{ minWidth: "150px", width: "150px", transform: "translateY(0)", top: "100%" }}>
                {options.map((option) => (
                    <a className="dropdown-item fs-6 pointer" onClick={() => optionHandler(option.value)} style={{ width: "150px" }}>{option.name}</a>
                ))}
            </div>
        </div>
    )
}
