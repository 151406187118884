import React, { useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import ReadonlyInputWithCopy from '../../Common/Inputs/ReadonlyInputWithCopy'

export default function ShareModal({ isOpen, closeModal, subadminId, subscriptionId }) {
    const [type, setType] = React.useState('yearly')
    const [link1, setLink1] = React.useState('')
    const [link2, setLink2] = React.useState('')

    useEffect(() => {
        if (subscriptionId) {
            const subscriptionUrl = `${process.env.REACT_APP_MEMBER_URL}/checkout/${subscriptionId}/${type}`;
            const checkoutUrl = `${process.env.REACT_APP_MEMBER_URL}/checkout/${subadminId}/${subscriptionId}/${type}`;
            setLink1(subscriptionUrl)
            setLink2(checkoutUrl)
        }
    }, [type])

    return (
        <Modal
            className="multiPurposePopup"
            show={isOpen}
            onHide={closeModal}
            backdrop="static"
        >
            <Modal.Header>
                <div
                    className="w-30 h-30 d-flex rounded-5 p-1 mt-3"
                    style={{ color: '#12aeb4', border: '1px solid #12aeb4' }}
                >
                    <FontAwesomeIcon icon={faShare} />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <select className="form-control"
                        onChange={(e) => setType(e.target.value)}
                    >
                        <option value="yearly">Yearly</option>
                        <option value="monthly">Monthly</option>
                        <option value="onetime">One Time</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="shareLink">Upgrade Url</label>
                    <ReadonlyInputWithCopy message={link1} />
                </div>
                <div className="form-group">
                    <label htmlFor="shareEmail">Checkout Url</label>
                    <ReadonlyInputWithCopy message={link2} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="w-100 d-flex justify-content-center">
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
