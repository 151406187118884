import React from 'react'

export default function ReadonlyInputWithCopy({ ...props }) {
    const [hover, setHover] = React.useState(false);
    const [isCopied, setIsCopied] = React.useState(false);
    
    const copyToClipboard = (inputValue) => {
        navigator.clipboard.writeText(inputValue)
          .then(() => {
            setIsCopied(true);
          })
      };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center border rounded p-1' >
                <input readOnly className='form-contol' style={{ width: '345px', border: 'transparent', fontSize: 'x-small' }} value={props?.message ?? ''} />
                <button className="btn p-1"
                    style={{ color: hover ? "white" : "#12aeb4", background: hover ? "#12aeb4" : '#c7ebeb', borderRadius: '20px', fontSize: '12px' }}
                    onClick={() => copyToClipboard(props?.message)}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    Copy
                </button>
            </div>
            {isCopied ? <small className="pl-1">link copied to clipboard</small> : ''}
        </div>
    )
}
