import React, { useState, useEffect } from "react";
import {
  eventDetails,
  getAllCommentsofEvents,
  saveEventCommentData,
} from "../../helper/service";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

import close2 from "../../images/dashboard/close-event.svg";
import calendar from "../../images/admin/calendar-sm.svg";
import submit2 from "../../images/admin/submit2.svg";
import addlink from "../../images/admin/icn-general-addlink.svg";
import defaultProfile from "../../images/defaultUser-image.jpg";

const ViewEventModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [commentElements, setCommentElements] = useState(null);
  const [event, setSelectedEvent] = useState({
    event_name: "",
    description: "",
    eventImg: "",
    eventFiles: [],
    url: "",
    eventDate: "",
    eventEndDate: "",
    startTime: "",
    endTime: "",
    currentTimeZone: "",
    clickEventId: "",
    eventAcceptIndPayment: "",
  });
  
  useEffect(() => {
    console.log(props, "the event coming");
    getEventDetails(props.eventId);
  }, [props.eventId]);
  
  const closeModal = () => {
    setIsOpen(false);
    resetStates();
    if (props.onClose) {
      props.onClose();
    }
  };
  const resetStates = () => {
    setSelectedEvent({
      clickEventId: "",
      event_name: "",
      description: "",
      eventDate: "",
      eventEndDate: "",
      startTime: "",
      endTime: "",
      currentTimeZone: "",
      eventImg: "",
      eventImg: "",
      eventFiles: [],
      url: "",
      eventAcceptIndPayment: "",
    });
    setCommentElements(null);
  };
  const getEventDetails = (eventId) => {
    props?.setLoader(true);
    eventDetails(eventId)
      .then((data) => {
        iterateComments(data?.result?.commentByUserId);

        const start = props?.formatDate(data.result.eventDate);
        const end = props?.formatDate(data.result.eventEndDate);

        let timeZone = start.timeZone;

        let startDateEvent = start.date;
        let startTime = start.time;

        let endDateEvent = end.date;
        let endTime = end.time;

        setSelectedEvent({
          clickEventId: data.result._id,
          event_name: data.result.name,
          description: data.result.description,
          eventDate: startDateEvent,
          eventEndDate: endDateEvent,
          startTime: startTime,
          endTime: endTime,
          currentTimeZone: timeZone,
          eventImg: data.result.image === "undefined" ? "" : data.result.image,
          url:
            data.result.liveStreamUrl !== "undefined"
              ? data.result.liveStreamUrl
              : "",
          eventFiles: data.result.eventFiles ? data.result.eventFiles : [],
          eventAcceptIndPayment: data.result?.eventAcceptIndPayment
            ? data.result?.eventAcceptIndPayment
            : false,
        });
        setIsOpen(true);
      })
      .finally(() => {
        props?.setLoader(false);
      });
  };
  const iterateComments = (comments) => {
    if (!comments || comments?.length === 0) {
      return;
    } else {
      const data = comments.map((sub) => {
        var fname = "";
        var lname = "";
        var img = defaultProfile;
        if (sub.subadminId) {
          fname = sub.subadminId.firstName;
          lname = sub.subadminId.lastName;
          img = !sub.subadminId.image ? defaultProfile : sub.subadminId.image;
        } else {
          fname = sub.userId.firstName;
          lname = sub.userId.lastName;
          img = !sub.userId.image ? defaultProfile : sub.userId.image;
        }

        return (
          <div className="media" key={sub._id}>
            {!img ? (
              <img src={defaultProfile} className="user-img img-fluid mr-2" />
            ) : (
              <img src={img} className="user-img img-fluid mr-2" />
            )}
            <div className="media-body">
              <h5 className="mt-0 comment-name">
                {fname} {lname}
              </h5>
              <p className="comment-text">{sub.comment}</p>
            </div>
          </div>
        );
      });
      setCommentElements(data);
    }
  };

  const saveEventcomment = async () => {
    if (comment) {
      props?.setLoader(true);

      try {
        const user_id = localStorage.getItem("user_id");
        const commentData = {
          eventId: event?.clickEventId,
          commentByUserId: {
            subadminId: user_id,
            comment: comment,
          },
        };
        await saveEventCommentData(commentData);
        setComment("");
        getTotalComment(event?.clickEventId);
      } catch (error) {
        const message="Error saving Comments" ,
        title = "Something Went Wrong!!",
        type="error";
        props?.setWarningModal(title,type,message,true)
      } finally {
        props?.setLoader(false);
      }
    }
  };
  const getTotalComment = (idd) => {
    getAllCommentsofEvents(idd)
      .then((data) => {
        if (data && data.result && data.result.commentByUserId) {
          iterateComments(data?.result?.commentByUserId);
        }
      })
      .catch((error) => {
        const message=  `Error fetching comments:, ${error}`,
        title = "Something Went Wrong!!",
        type="error";
        props?.setWarningModal(title,type,message,true)
      });
  };
  const shortenFileName = (fileName) => {
    const maxLength = 30;
    if (fileName.length <= maxLength) return fileName;
    const start = fileName.substring(0, 20);
    const end = fileName.substring(fileName.length - 7);
    return `${start}...${end}`;
  };
  const onChange = (event) => {
    setComment(event.target.value);
  };
  return (
    <>
      <Modal
        show={isOpen}
        onHide={closeModal}
        className="viewEventModal"
        id="viewEventModal"
        backdrop="static"
      >
        <Modal.Header className="border-0 align-items-center">
          <h5 className="modal-title">View Event</h5>
          <div className="ml-auto">
            <span
              className="share"
              name="viewEventModal mr-0"
              onClick={(e) =>
                props.shareEvent(e,event.clickEventId,event.eventAcceptIndPayment)
              }
            >
              Share
            </span>
            <span className='edit ml-auto' onClick={props.editEvent}>Edit</span>
          </div>
          <button
            type="button"
            className="close ml-2 p-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">
              <img src={close2} />
            </span>
          </button>
        </Modal.Header>
        <div
          className="flex"
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <label
            className="text"
            style={{
              fontWeight: "bold",
              color: "#343434",
              flex: "1 1 100%",
              wordBreak: "break-word",
            }}
          >
            <h5>Event Title: {event?.event_name}</h5>
          </label>
        </div>

        <div className="event-img mb-3 px-3">
          {event?.eventImg?.includes("png") ||
          event?.eventImg?.includes("PNG") ||
          event?.eventImg?.includes("jpeg") ||
          event?.eventImg?.includes("jpg") ||
          event?.eventImg?.includes("jfif") ||
          event?.eventImg?.includes("webp") ||
          event?.eventImg?.includes("gif") ||
          event?.eventImg?.includes("bmp") ||
          event?.eventImg?.includes("svg") ? (
            <div>
              <img src={event?.eventImg} />{" "}
            </div>
          ) : event?.eventImg?.includes("mp4") ||
            event?.eventImg?.includes("mkv") ||
            event?.eventImg?.includes("mov") ||
            event?.eventImg?.includes("webm") ? (
            <div>
              <ReactPlayer
                url={event?.eventImg}
                controls={true}
                playing={false}
                width="400"
                height="200px"
              />
            </div>
          ) : event?.eventImg?.includes("mp3") ||
            event?.eventImg?.includes("wav") ||
            event?.eventImg?.includes("ogg") ? (
            <div>
              <ReactPlayer
                url={event?.eventImg}
                controls={true}
                playing={false}
                className="w-100"
                height="80px" // Reduced height for audio files
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="event-img" style={{ marginTop: "1em" }}>
          {event?.eventFiles?.length > 0 &&
            Array.isArray(event?.eventFiles) &&
            event?.eventFiles?.map((file, index) => (
              <div key={index}>
                {file instanceof File ? (
                  <a
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noreferrer"
                    title={file.name.substring(file.name.lastIndexOf("/") + 1)}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <span className="ml-3">
                      {shortenFileName(
                        file.name.substring(file.name.lastIndexOf("/") + 1)
                      )}
                    </span>
                    <img
                      src={require("../../images/download (2).png")}
                      alt="download img"
                      style={{ marginRight: "10px" }}
                      className="download-data"
                    />
                  </a>
                ) : (
                  <a
                    href={file}
                    target="_blank"
                    rel="noreferrer"
                    title={file.substring(file.lastIndexOf("/") + 1)}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <span className="ml-3">
                      {shortenFileName(
                        file.substring(file.lastIndexOf("/") + 1)
                      )}
                    </span>
                    <img
                      src={require("../../images/download (2).png")}
                      alt="download img"
                      style={{ marginRight: "10px" }}
                      className="download-data"
                    />
                  </a>
                )}
              </div>
            ))}
        </div>
        <Modal.Body>
          <div className="d-flex justify-content-between align-items-start flex-wrap">
            <img src={calendar} />
            <div className="event-date mr-auto">
              {new Date(event?.eventDate).toLocaleDateString()} -{" "}
              {new Date(event?.eventEndDate).toLocaleDateString()}
              <br />
              <>
                Event from : {event?.startTime} -{" "}
                {event?.endTime} {event?.currentTimeZone}
              </>
            </div>
            <div>
              {event?.url == "" && "undefined" ? (
                <span className="event-type">
                  <span className="physical-event-color">Physical event</span>
                </span>
              ) : (
                <>
                  <a
                    href={`${event?.url}`}
                    target="_blank"
                    className="event-link"
                  >
                    EVENT LINK{" "}
                    <img
                      src={addlink}
                      width={16}
                      height={16}
                      className="ml-1"
                    />
                  </a>
                  <br />
                </>
              )}
            </div>
          </div>
          <div className="event-description">
            <h3 className="title">Description</h3>
            <div
              className="description-content text"
              dangerouslySetInnerHTML={{ __html: event?.description }}
            ></div>
          </div>
          <div className="participants">
            <div className="d-flex justify-content-left flex-wrap">
              {/* {audienceData} */}
            </div>
          </div>
          <div className="comments">
            <h4 className="title">Comments</h4>
            <div className="comment-list">{commentElements}</div>
            <div className="new-msg d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Type your message here…"
                name="comment"
                onChange={onChange}
                value={comment}
              />
              <img
                src={submit2}
                className="ml-2 message-cursor"
                style={{ cursor: "pointer" }}
                onClick={saveEventcomment}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewEventModal;