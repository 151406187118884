const getGmtOffset = () => {
    const timeZone = localStorage.getItem("websiteTimeZone") || "";
    return getGMTOffsetFromTimeZone(timeZone);
}

const getGMTOffsetFromTimeZone = (timeZone) => {
    if (!timeZone) return "";

    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "short",
    });

    const parts = formatter.formatToParts(new Date());
    const gmtPart = parts.find((part) => part.type === "timeZoneName");

    return gmtPart ? gmtPart.value : "";
};

function formatDateTime(date) {
    
    // If date is not a Date object, convert it to one
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const padZero = (num) => String(num).padStart(2, '0');

    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();

    const hours = padZero(date.getHours());
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

export { getGmtOffset, getGMTOffsetFromTimeZone, formatDateTime };