import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";

import SunRichTextEditor from "../RichTextEditor/SunRichTextEditor.js";
import Header from "../Common/Header";
import NewSidebar from "../new-sidebar";
import ConfirmationModal from "../Setting/ConfirmationModal";
import SuccessPopup from "../Setting/Sucesspopup";
import Loader from "../../Loader.js";

import {
  createEmailTemplate,
  updateEmailTemplate,
} from "../../helper/service.ts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";

import infoIcon from "../../images/dashboard/ic_error.svg";

const supporedTokens = [
  // "{{MemberFirstName}}",
  // "{{MemberLastName}}",
  // "{{MemberEmail}}",
  // "{{AdminFirstName}}",
  // "{{AdminLastName}}",
  // "{{AdminEmail}}",
  // "{{AdminPhone}}",
  // "{{AdminCompanyName}}",
  // "{{AdminAddress}}",
  // "{{AdminMembershipName}}"
  "{{SubscriberName}} - Name of the subscriber",
  "{{SubscriberEmail}} - Email of the subscriber",
  "{{SubscribeConfirmationLink}} - Link to confirm as a subscriber",
]

const EmailTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const { id } = useParams();
  const inputFileRef = useRef();

  const [subject, setSubject] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [attachments, setAttachments] = useState(null);
  const [attachmentsName, setAttachmentsName] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [isLoading, setIsLoeading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [mdlMessage, setMdlMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    if (email) {
      if(email?.emailSubject){
        setSubject(email?.emailSubject);
        setMsgContent(email?.emailMessage);
        setAttachments(email?.files??"");
        const fileName = email?.files?.split("/").pop();
        setAttachmentsName(fileName??"");
      }
      else
      {
        setTemplateId(email._id);
        setSubject(email?.templateSubject);
        setMsgContent(email?.templateMessage);
        setAttachments(email?.files??"");
        const fileName = email?.files?.split("/").pop();
        setAttachmentsName(fileName??"");

        if(id)
        {
          setIsEdit(true);
        }else{
          setIsEdit(false);
        }
      }
    }
  }, [email]);

  const resetStates = () => {
    setIsLoeading(false);
    setSubject("");
    setMsgContent("");
    setAttachments("");
    setAttachmentsName("");
  };

  const countDescriptionWords = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;

    const images = tempDiv.querySelectorAll("img");
    images.forEach((img) => {
      img.outerHTML = "X";
    });
    const videos = tempDiv.querySelectorAll("iframe");
    videos.forEach((vid) => {
      vid.outerHTML = "X";
    });
    const audios = tempDiv.querySelectorAll("audio");
    audios.forEach((aud) => {
      aud.outerHTML = "X";
    });
    return tempDiv.innerText.trim().length;
  };

  const handlSunRichtextEditorChange = (content) => {
    const count = countDescriptionWords(content);
    if (count !== 0) {
      setMsgContent(content);
    }
  };

  const onResourcesChange = (event) => {
    if (event?.target?.files?.[0]?.size > 50e6) {
      setMdlMessage("Please upload a file smaller than 50 MB.");
      setWarningModal(true);
      setModalType("error");
      setModalTitle("Action Required");
      event.target.value = "";
      return;
    } else {
      if (event.target.files) {
        const file = event?.target?.files?.[0];
        setAttachments(event?.target?.files?.[0] ?? "");
        setAttachmentsName(file.name);
      }
    }
  };

  const handleRemoveResource = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    setAttachments("");
    setAttachmentsName("");
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsLoeading(true);
    const data = {
      templateSubject: subject,
      templateMessage: msgContent,
      files: attachments,
    };

    const validationSchema = Yup.object().shape({
      templateSubject: Yup.string().required("Subject is required"),
      templateMessage: Yup.string().required("Content is required"),
    });

    validationSchema
      .validate(data)
      .then(async (validData) => {
        await (isEdit
          ? updateEmailTemplate(templateId, validData)
          : createEmailTemplate(validData)
        )
          .then((res) => {
            if (res.status === 200) {
              setIsSuccess(true);
              setMdlMessage(`${res.message}`);
              resetStates();
            } else {
              setWarningModal(true);
              setModalType("info");
              setModalTitle("FYI");
              setMdlMessage(`${res.message}`);
            }
          })
          .catch((error) => {
            setIsLoeading(false);
            setWarningModal(true);
            setModalType("error");
            setModalTitle("Error!!");
            setMdlMessage(`${error}`);
          })
          .finally(() => {
            setIsLoeading(false);
          });
      })
      .catch((error) => {
        var errMsg = `${error}`;
        errMsg = errMsg.split(":")[1];
        setIsLoeading(false);
        setWarningModal(true);
        setModalType("error");
        setModalTitle("Error!!");
        setMdlMessage(`${errMsg}`);
      })
      .finally(() => {
        setIsLoeading(false);
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <SuccessPopup
          message={mdlMessage}
          closeSuccessModel={() => {
            if(id && !isEdit){
              navigate(`/email-automation/${id}`)
            }else{
              navigate(-1);
            }
          }}
        />
      )}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={modalTitle}
          message={mdlMessage}
          onConfirm={() => {
            setModalTitle("");
            setModalType("");
            setMdlMessage("");
            setWarningModal(false);
          }}
        />
      )}
      <Header />
      <NewSidebar />
      <div className="content-wrapper admin-courses">
        <div className="content-header mb-4 email-creation">
          <div className="row w-100 px-4">
            <span
              className="d-flex align-items-center fs-6 pointer w-25"
              style={{ color: "#12aeb4" }}
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon={faCircleLeft}
                color="#12aeb4"
                style={{ height: "25px", marginRight: ".5rem" }}
              />{" "}
              Go Back
            </span>
          </div>
          <section className="email-messaging">
            <div className="content-fluid px-3 py-2">
              <div className="row mt-4 p-2">
                <div className="col-lg-6 col-md-8 col-sm-10">
                  <h2 className="title">Create Your Email Template</h2>
                  <div className="text mb-0">
                    <p>
                      Create email templates to save for later use in automation. These templates allow you to deliver
                      messages straight to your members' inboxes, ensuring a
                      seamless communication experience. Members can reply
                      directly to your company's email address, and all emails
                      will be sent from the email address you have set up for
                      your organization.
                    </p>
                  </div>                 
                </div>
                <div className="col-lg-6 col-md-8 col-sm-10">
                  <div>
                    <h3 className="title">Supported Tokens</h3>
                    <div className="alert alert-primary" role="alert">
                      Token are only supported for the email when a member subscribes.
                    </div>
                    <ul>
                      {supporedTokens.map((token, index) => (
                        <li key={index}>{token}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="content-fluid mt-0 p-4 wis-modal w-75">
              <form name="form" onSubmit={submitForm}>
                <div className="form-group">
                  <label>
                    Subject
                    <small> Max 500 Characters</small>
                  </label>
                  <input
                    type="text"
                    value={subject}
                    // required
                    maxLength={500}
                    name="subject"
                    className="form-control"
                    onChange={(event) => {
                      setSubject(event.target.value);
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Message</label>
                  <SunRichTextEditor
                    value={msgContent}
                    handlSunRichtextEditorChange={handlSunRichtextEditorChange}
                  />
                </div>

                <div className="form-group w-50">
                  <label>
                    Attach Resource(s){" "}
                    <img src={infoIcon} alt="info" width="16" className="mx-2" />
                    <small>
                      Add a new file direct from your device in PDF, Video,
                      Audio, Word doc, Excel Doc format.
                    </small>
                  </label>
                  <div className="position-relative">
                    <input
                      type="file"
                      ref={inputFileRef}
                      className="form-control direct-add-file px-0 h-100"
                      onChange={onResourcesChange}
                      name="attachments"
                    />
                    {attachmentsName && (
                      <div className="resourcesLists">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "7px",
                            marginBottom: "7px",
                          }}
                        >
                          <small style={{ wordBreak: "break-word" }}>
                            {attachmentsName}
                          </small>
                          <span
                            onClick={handleRemoveResource}
                            style={{
                              marginRight: "3px",
                              cursor: "pointer",
                              color: "#00aeb3",
                            }}
                          >
                            <FontAwesomeIcon icon={faXmarkSquare} />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="d-flex gap-3">
                    <button
                      type="submit"
                      className="email-btn-custom"
                      value="save"
                    >
                      {!isEdit ? "Save" : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default EmailTemplate;
